import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import TitleIcone from "../../../static/assets/Features/TitleIcon.svg";

// This file is used at : [ clean-clinic.js | rakuten.js ]

const RacutenResultComponent = (props) => {
  const { resultsTitle, resultsDescription, projectFirstImage } = props;
  return (
    <div className="results-section">
      <div className="full-container">
        <div className="home-section-textIcone">
          <TitleIcone className="home-section-titleIcone" />
          <h4 className="results-section-title">{resultsTitle}</h4>
        </div>
        <div className="results-section-text">
          <p
            className="results-section-description"
            dangerouslySetInnerHTML={{
              __html: resultsDescription,
            }}
          ></p>
        </div>

        <div className="projects-section-wrapper">
          <div className=" projects-section-colImage">
            <GatsbyImage
              alt="projectFirst"
              image={projectFirstImage}
              className="projects-section-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RacutenResultComponent;
